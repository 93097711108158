import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import i18next from 'i18next';
import Container from '../../common/Container';
import Logo from '../../common/Logo';
import Nav from './FooterNav';
import SocialIcons from './SocialIcons';
function getLanguage() {
    if (typeof window !== 'undefined' && window.localStorage) {
        const language = window.localStorage.getItem('i18nextLng');
        console.log(language, 'Language'); // Check this log
        return getTermsLink(language);
    }
    console.log('No value');
    return getTermsLink('');
}
function getTermsLink(language) {
    console.log(language, 'switch'); // Check this log
    switch (language) {
        case 'uk':
            console.log(language, 'UK CASE');
            return 'https://www.iubenda.com/terms-and-conditions/57506673';
        case 'us':
            return 'https://www.iubenda.com/terms-and-conditions/62145648';
        case 'it':
            console.log(language, 'IT CASE'); // Check this log
            return 'https://www.iubenda.com/termini-e-condizioni/20984338';
        case 'es':
            return 'https://www.iubenda.com/condiciones-de-uso/45646763';
        case 'pt':
            return 'https://www.iubenda.com/termos-e-condicoes/13446623';
        default:
            console.log(language, 'DEFAULT CASE');
            return 'https://www.iubenda.com/terms-and-conditions/57506673';
    }
}
export default function Footer() {
    const termsLink = getLanguage();
    console.log(termsLink, 'Terms Link'); // Check this log
    const navSections = [
        {
            title: 'Help',
            links: [
                [
                    'customer-support',
                    i18next.language == 'it' ? '/contatti' : '/contact',
                ],
                ['blog', '/blog'],
                ['faq', '/support'],
            ],
        },
        {
            title: 'Legal',
            links: [
                ['terms-n-conditions', termsLink],
                ['privacy-policy', '/privacy-policy'],
                ['cookies', '/privacy-policy#cookies'],
                ['google-api-disclosure', '/google-api-disclosure'],
            ],
        },
    ];
    return (_jsx("footer", { className: "py-6 text-day-mode-text-primary md:py-12 lg:py-16", children: _jsx(Container, { children: _jsxs("div", { className: "space-y-8 sm:flex sm:flex-col sm:space-y-14", children: [_jsxs("div", { className: "space-y-8 sm:flex sm:space-x-12 sm:space-y-0 md:space-x-24", children: [_jsxs("div", { className: "space-y-8", children: [_jsx(Logo, { className: "w-60" }), _jsx(SocialIcons, {})] }), _jsx(Nav, { sections: navSections })] }), _jsxs("p", { className: "text-sm text-day-mode-text-secondary", children: ["Copyright \u00A9 ", new Date().getFullYear(), " Together Price Ltd - 86 Jermyn St, St. James's, London SW1Y 6AW, UK"] })] }) }) }));
}
